import React from 'react'
import {
  string, arrayOf, shape, number,
} from 'prop-types'

import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import favicon from '../images/favicon.png'

const SEO = ({
  description = '',
  lang = 'fr',
  meta = [],
  title,
  keywords,
  image: metaImage,
}) => {
  const { site, file } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            siteUrl
          }
        }
        file(relativePath: { eq: "seo-home.jpg" }) {
          childImageSharp {
            resize {
              src
              width
              height
            }
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description
  const metaKeywords =
  (keywords || site.siteMetadata.keywords || []).join(',') || ''

  let image = null
  if (metaImage && metaImage.src && metaImage.width && metaImage.height) {
    image = {
      ...metaImage,
      src: `${site.siteMetadata.siteUrl}${metaImage.src}`,
    }
  } else if (file) {
    image = {
      ...file.childImageSharp.resize,
      src: `${site.siteMetadata.siteUrl}${file.childImageSharp.resize.src}`,
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[
        { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
      ]}
      meta={[
        {
          name: 'keywords',
          content: metaKeywords,
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        // {
        //   name: 'twitter:creator',
        //   content: site.siteMetadata.author,
        // },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ]
        .concat(
          image
            ? [
              {
                property: 'og:image',
                content: image.src,
              },
              {
                property: 'og:image:width',
                content: image.width,
              },
              {
                property: 'og:image:height',
                content: image.height,
              },
              {
                name: 'twitter:card',
                content: 'summary_large_image',
              },
            ]
            : [
              {
                name: 'twitter:card',
                content: 'summary',
              },
            ],
        )
        .concat(meta)}
    />
  )
}

export default SEO

SEO.defaultProps = {
  description: '',
  lang: 'fr',
  meta: [],
  keywords: null,
  image: null,
}

SEO.propTypes = {
  description: string,
  lang: string,
  meta: arrayOf(shape({ name: string.isRequired, content: string.isRequired })),
  title: string.isRequired,
  keywords: arrayOf(string),
  image: shape({
    src: string.isRequired,
    width: number.isRequired,
    height: number.isRequired,
  }),
}
